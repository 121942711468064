import React from "react";
import { Container, Flex, Box, Heading, css } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import FlexImage from "@solid-ui-components/FlexImage";
import FlexContent from "@solid-ui-components/FlexContent";
import FlexOverlapFade from "@solid-ui-components/FlexOverlapFade";
import ContentImages from "@solid-ui-components/ContentImages";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import PlayerModal from "@solid-ui-blocks/Modal/PlayerModal";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import getImage from "@solid-ui-components/utils/getImage";
import { v4 } from 'uuid';

const styles = {
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`,
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: (t) => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`,
  },
  number: {
    color: `white`,
    fontWeight: `body`,
    borderRadius: `full`,
    bg: `alpha`,
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0,
  },
  avatar: {
    width: 250,
  },
};

const FeaturesWithPhotoBlock07 = ({
  content: { kicker, avatar, title, text_1, text_2, text_3, images, collection, video },
  reverse,
}) => (
  <Container key={v4()} sx={{ position: `relative` }}>
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`,
        ],
        mx: [null, null, null, -4],
      }}
    >
      <FlexImage reverse={reverse}>
        <div style={{ position: `relative` }}>
          <ContentImages content={{ images }} reverse={reverse} imgStyle={{ borderRadius: '12px' }} />
          {video?.src?.publicURL &&
            <div
              style={{
                position: "absolute",
                top: 'calc(50% - 24px)',
                left: 'calc(50% - 32px)'
              }}
            >
              <PlayerModal video={video} />
            </div>
          }
        </div>
      </FlexImage>
      <FlexContent reverse={reverse}>
        <Img
          image={getImage(avatar?.src)}
          alt={avatar?.alt}
          css={css(styles.avatar)}
        />
        <StackTitle
          kicker={kicker}
          title={title}
          lines={[text_1, text_2, text_3]}
          align="left"
        />
        <Reveal effect={reverse ? "fadeInRight" : "fadeInLeft"} duration={1.5}>
          {collection?.map(({ title, description }, index) => (
            <Flex key={`item-${index}`} sx={styles.listItem}>
              <Box sx={{ position: `relative`, flexShrink: 0, mr: 4 }}>
                <Heading variant="h4" as="div" color="white" sx={styles.number}>
                  {index + 1}
                </Heading>
                {index + 1 < collection.length && <Box sx={styles.line} />}
              </Box>
              <Box>
                <ContentText content={title} variant="h5" mb="2" />
                <ContentText
                  content={description}
                  sx={{ maxWidth: 330 }}
                  mb="4"
                />
              </Box>
            </Flex>
          ))}
        </Reveal>
      </FlexContent>
    </Flex>
    <FlexOverlapFade direction={reverse ? "ltr" : "rtl"} />
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock07);
