import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Box } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import GetStarted from "@solid-ui-blocks/CallToAction/Block01";
import StackTitle from "@solid-ui-components/StackTitle";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block07";
import Tabs from "@solid-ui-components/Tabs";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper from '@helpers/appContext';
import theme from "./_theme";
import { styles } from "./styles/_products.styles";
import { v4 } from 'uuid';

const ProductsSection = ({ products, space = 4 }) => (
  <Box key={v4()}>
    {products?.collection?.map((data, index) => {
      const rev = index % 2 === 0 ? "reverse" : "";
      const stl =
        index % 2 === 0
          ? styles.featureTwoContainer
          : styles.featureOneContainer;
      return (
        <Container key={v4()} variant="wide" sx={stl}>
          <FeatureOne content={data} reverse={rev} />
          <Divider space={space} />
        </Container>
      );
    })}
  </Box>
);

const Products = (props) => {
  const { selectedLanguage  } = props;
  const { allBlockContent } = useStaticQuery(query);

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  const productsHeader = content[`products-header.${selectedLanguage}`];
  const existingProducts = content[`existing-products.${selectedLanguage}`];
  const upcomingProducts = content[`upcoming-products.${selectedLanguage}`];

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Products" />

      <Divider space="5" />
      <Divider space="5" />

      <StackTitle
        kicker={productsHeader.kicker}
        title={productsHeader.title}
        lines={[productsHeader.text_1, productsHeader.text_2]}
        space={0}
      />
      
      <Container variant="wide">
        <Tabs space={4}>
          {/* Existing Products Tab */}
          <div content={existingProducts}>
            <ProductsSection products={existingProducts} space={2} />
          </div>

          {/* Upcoming Products Tab */}
          <div content={upcomingProducts}>
            <ProductsSection products={upcomingProducts} />
          </div>
        </Tabs>
      </Container>
      
      <Divider space="4" />

      {/* Amera Early Access */}
      <Container sx={styles.getStartedContainer}>
        <GetStarted content={content[`book-demo.${selectedLanguage}`]} />
      </Container>
      <Divider space="5" />

    </Layout>
  );
};

const query = graphql`
  query siteProductsBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/products/products-header"
            "site/products/existing-products"
            "site/products/upcoming-products"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const ProductsPage = (props) => (
  <AppWrapper>
    <Products {...props} />
  </AppWrapper>
);

export default ProductsPage;
